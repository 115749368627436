<template>
  <div v-if="!$store.state.pcshow" class="con">
    <img src="../assets/img/img24.png" alt="">
    <div class="yj2">
      <h4><span></span>优势与特色</h4>
      <div class="bus">
        <img src="../assets/img/img25.png" alt="">
        <h3>重视质量</h3>
        <p>我们拥有一套使我们能够在整个组织保持质量文化的框架和理念，这是我们所有工作的核心。</p>
      </div>
      <div class="bus">
        <img src="../assets/img/img26.png" alt="">
        <h3>长于管理</h3>
        <p>我们的核心竞争优势是通过全面和一致的流程进行项目管理，致力于为企业提供高质量信息。我们高度积极的回应客户，并提供定制服务，这些服务可以单独或作为综合的“全面服务”解决方案的一部分提供。我们项目团队制定整合性的计划，将组织内从项目工作者到高层管理等所有相关人员结合起来，以确保业务关系顺畅。同时有自主研发的项目管理系统和eTMF系统。</p>
      </div>
      <div class="bus">
        <img src="../assets/img/img53.png" alt="">
        <h3>高效的数据管理</h3>
        <p>我们的数据管理人员熟练的掌握技术和标准，并且我们和所有领先的供应商建立了合作伙伴关系，包括：Medidata(RAVE) 、MobileMD(eCollect)、遥领科技（VERIFY）、百奥知（Bioknow-EDC）。</p>
        <p>我们的数据管理人员都经过包括CDISC的最新标准使用的培训，我们采用这些标准，以便进行有效的审查、处理数据传输和提交给监管机构。</p>
      </div>
      <div class="bus">
        <img src="../assets/img/img27.png" alt="">
        <h3>合规的财务管理</h3>
        <p>财务管理和支付系统与项目管理系统对接，使项目支出便捷精准，为大型的上市后研究和流行病学研究提供助力。</p>
      </div>
    </div>
    <div class="yj yjbg">
      <h4><span></span>现有业务</h4>
      <ul>
        <li>
          <img src="../assets/img/img29.png" alt="">
          <p>与国内知名药厂签订的CSO订单高达上千万元</p>
        </li>
        <li>
          <img src="../assets/img/img30.png" alt="">
          <p>PD-1单抗治疗晚期肝细胞癌的回顾性真实世界临床研究</p>
        </li>
        <li>
          <img src="../assets/img/img31.png" alt="">
          <p>卡瑞利珠单抗治疗晚期非小细胞肺癌患者的真实世界临床研究</p>
        </li>
        <li>
          <img src="../assets/img/img32.png" alt="">
          <p>骨修复材料用于全内镜下腰椎椎体间融合术的真实世界研究</p>
        </li>
      </ul>
    </div>
    <div class="yj">
      <h4><span></span>全链条服务支持</h4>
      <div class="support">
        <img src="../assets/img/companyIntro19.png" alt="">
        <div class="supportcon clearfix">
          <div class="con_left">
            <h5>临床开发计划</h5>
            <ul>
              <li><span></span>临床开发计划</li>
              <li><span></span>临床试验设计</li>
            </ul>
          </div>
          <div class="con_left">
            <h5>注册事务</h5>
            <ul>
              <li><span></span>药政法规咨询</li>
              <li><span></span>资料翻译</li>
              <li><span></span>申报资料审核完善</li>
              <li><span></span>审评进度跟踪</li>
              <li><span></span>人类遗传办申报</li>
              <li><span></span>IND/NDA申报</li>
            </ul>
          </div>
        </div>
        <img src="../assets/img/companyIntro18.png" alt="">
        <div class="supportcon clearfix">
          <div class="con_left">
            <h5>临床服务</h5>
            <ul>
              <li><span></span>研究中心可行性分析</li>
              <li><span></span>项目实施管理</li>
              <li><span></span>项目监查</li>
              <li><span></span>项目质量控制</li>
              <li><span></span>GCP、注册法规培训</li>
              <li><span></span>中心管理组织SMO</li>
            </ul>
          </div>
          <div class="con_left">
            <h5>医学事务</h5>
            <ul>
              <li><span></span>医学顾问</li>
              <li><span></span>医学写作</li>
              <li><span></span>医学监察</li>
              <li><span></span>药物警戒</li>
            </ul>
          </div>
        </div>
        <img src="../assets/img/companyIntro20.png" alt="">
        <div class="supportcon clearfix">
          <div class="con_left">
            <h5>数据管理与统计分析</h5>
            <ul>
              <li><span></span>数据管理与统计计划</li>
              <li><span></span>数据核查、数据质量控制</li>
              <li><span></span>统计分析报告撰写</li>
            </ul>
          </div>
          <div class="con_left">
            <h5>数字化健康社群</h5>
            <ul>
              <li><span></span>用户UGC内容分享</li>
              <li><span></span>医生PGC付费内容输出</li>
              <li><span></span>企业广告投放</li>
              <li><span></span>医院资源整合</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bussinessCntainer" v-else>
    <div><img src="../assets/img/BusinessIntroduction/bannerTop.jpg" class="topBg" /></div>
    <div class="goodness">优势与特色</div>
    <div class="line"></div>
    <div class="goodnessCon">
      <div @mouseover="hovers(1)" @mouseout="outs(1)">
        <div :class="hovers1?'one2':'one'">
          <img src="../assets/img/BusinessIntroduction/img11.png" class="img1" />
          <div class="desc">
            <div class="qualityText">重视质量</div>
            <div class="qualityDesc">Attach importance to quality</div>
          </div>
        </div>
        <div :class="hovers1?'one':'one2'">
          <img src="../assets/img/BusinessIntroduction/qualityPic1.png" class="img13" />
          <div class="details">
          <img src="../assets/img/BusinessIntroduction/left.png" class="leftPic" />
            我们拥有一套使我们能够在整个组
            织保持质量文化的框架和理念，这
            是我们所有工作的核心。
            <img src="../assets/img/BusinessIntroduction/right.png" class="rightPic1" />
          </div>
        </div>
      </div>
      <div @mouseover="hovers(2)" @mouseout="outs(2)">
          <div :class="hovers2?'one2 lefts':'one lefts'">
            <img src="../assets/img/BusinessIntroduction/img2.png" class="img1" />
            <div class="desc">
              <div class="qualityText">长于管理</div>
              <div class="qualityDesc">Good at management</div>
            </div>
          </div>
          <div :class="hovers2?'one lefts':'one2 lefts'">
            <img src="../assets/img/BusinessIntroduction/qualityPic2.png" class="img13" />
            
            <div class="details">
              <img src="../assets/img/BusinessIntroduction/left.png" class="leftPic" />
              我们的核心竞争优势是通过全面和
              一致的流程进行项目管理，致力于
              为企业提供高质量信息。我们高度
              积极的回应客户，并提供定制服务，
              这些服务可以单独或作为综合的“全
              面服务”解决方案的一部分提供。
              我们项目团队制定整合性的计划，
              将组织内从项目工作者到高层管理
              等所有相关人员结合起来，以确保
              业务关系顺畅。同时有自主研发的
              项目管理系统和eTMF系统。
              <img src="../assets/img/BusinessIntroduction/right.png" class="rightPic2" />
            </div>
          </div>
      </div>
      <div @mouseover="hovers(3)" @mouseout="outs(3)">
          <div :class="hovers3?'one2 lefts':'one lefts'">
            <img src="../assets/img/BusinessIntroduction/img3.png" class="img1" />
            <div class="desc">
              <div class="qualityText">高效的数据管理</div>
              <div class="qualityDesc">Efficient data management</div>
            </div>
          </div>
          <div :class="hovers3?'one lefts':'one2 lefts'">
            <img src="../assets/img/BusinessIntroduction/qualityPic3.png" class="img13" />
            <div class="details">
              <div>
                <img src="../assets/img/BusinessIntroduction/left.png" class="leftPic" />
                我们的数据管理人员熟练的掌握技
                术和标准，并且我们和所有领先的
                供应商建立了合作伙伴关系，包括：
                Medidata(RAVE) 、MobileMD(eCo
                llect)、遥领科技（VERIFY）、百奥
                知（Bioknow-EDC）。
              </div>
              <div style="margin-top:8px;">我们的数据管理人员都经过包括CDI
              SC的最新标准使用的培训，我们采
              用这些标准，以便进行有效的审查、
              处理数据传输和提交给监管机构。
              <img src="../assets/img/BusinessIntroduction/right.png" class="rightPic3" />
              </div>
            </div>
          </div>
      </div>
      <div @mouseover="hovers(4)" @mouseout="outs(4)">
          <div :class="hovers4?'one2 lefts':'one lefts'">
            <img src="../assets/img/BusinessIntroduction/img4.png" class="img1" />
            <div class="desc">
              <div class="qualityText">合规的财务管理</div>
              <div class="qualityDesc">Compliance financial management</div>
            </div>
          </div>
          <div :class="hovers4?'one lefts':'one2 lefts'">
            <img src="../assets/img/BusinessIntroduction/qualityPic4.png" class="img13" />
            
            <div class="details">
              <img src="../assets/img/BusinessIntroduction/left.png" class="leftPic" />
              财务管理和支付系统与项目管理系
              统对接，使项目支出便捷精准，为
              大型的上市后研究和流行病学研究
              提供助力。
              <img src="../assets/img/BusinessIntroduction/right.png" class="rightPic4" />
            </div>
          </div>
      </div>
      
      
      
    </div>
    <div class="goodness">现有业务</div>
    <div class="line"></div>
    <div class="alreadyModuel">
      <img src="../assets/img/BusinessIntroduction/services.png" class="serviceImg" />
    </div>
    <div class="goodness">高品质服务</div>
    <div class="line"></div>
    <div class="qualityCon" >
      <div  @mouseover="hovers(5)" @mouseout="outs(5)">
          <div  :class="hovers5?'qualityPart2':'qualityPart'">
            <img src="../assets/img/BusinessIntroduction/quality1.png" class="qualityImg" />
            <div class="name">临床开发计划</div>
          </div>
          <div :class="hovers5?'qualityPart':'qualityPart2'">
            <div class="modals2"></div>
            <img src="../assets/img/BusinessIntroduction/quality1.png" class="img14" />
            <div class="details2">
              <div>临床开发计划</div>
              <div class="tops2">临床试验设计</div>
            </div>
          </div>
      </div>
      <div  @mouseover="hovers(6)" @mouseout="outs(6)">
          <div :class="hovers6?'qualityPart2 lefts2':'qualityPart lefts2'">
            <img src="../assets/img/BusinessIntroduction/quality2.png" class="qualityImg" />
            <div class="name">注册事务</div>
          </div>
          <div :class="hovers6?'qualityPart lefts2':'qualityPart2 lefts2'">
            <div class="modals2"></div>
            <img src="../assets/img/BusinessIntroduction/quality2.png" class="img14" />
            <div class="details2">
              <div>药政法规咨询</div>
              <div class="tops2">资料翻译</div>
              <div class="tops2">申报资料审核完善 </div>
              <div class="tops2">审评进度跟踪  </div>
              <div class="tops2">人类遗传办申报 </div>
              <div class="tops2">IND/NDA申报 </div>
            </div>
          </div>
      </div>
      
      <div  @mouseover="hovers(7)" @mouseout="outs(7)">
          <div :class="hovers7?'qualityPart2 lefts2':'qualityPart lefts2'">
            <img src="../assets/img/BusinessIntroduction/quality3.png" class="qualityImg" />
            <div class="name">临床服务</div>
          </div>
          <div :class="hovers7?'qualityPart lefts2':'qualityPart2 lefts2'">
            <div class="modals2"></div>
            <img src="../assets/img/BusinessIntroduction/quality3.png" class="img14" />
            <div class="details2">
              <div>研究中心可行性分析 </div>
              <div class="tops2">项目实施管理 </div>
              <div class="tops2">项目监查  </div>
              <div class="tops2">项目质量控制   </div>
              <div class="tops2">GCP、注册法规培训  </div>
              <div class="tops2">中心管理组织SMO   </div>
            </div>
          </div>
      </div>
      <div  @mouseover="hovers(8)" @mouseout="outs(8)">
          <div :class="hovers8?'qualityPart2 tops':'qualityPart tops'">
            <img src="../assets/img/BusinessIntroduction/quality4.png" class="qualityImg" />
            <div class="name">医学事务</div>
          </div>
          <div :class="hovers8?'qualityPart tops':'qualityPart2 tops'">
            <div class="modals2"></div>
            <img src="../assets/img/BusinessIntroduction/quality4.png" class="img14" />
            <div class="details2">
              <div>医学顾问 </div>
              <div class="tops2">医学写作 </div>
              <div class="tops2">医学监察  </div>
              <div class="tops2">药物警戒  </div>
            </div>
          </div>
      </div>
      <div  @mouseover="hovers(9)" @mouseout="outs(9)">
          <div :class="hovers9?'qualityPart2 lefts2 tops':'qualityPart lefts2 tops'">
            <img src="../assets/img/BusinessIntroduction/quality5.png" class="qualityImg" />
            <div class="name">数据管理与统计分析</div>
          </div>
          <div :class="hovers9?'qualityPart lefts2 tops':'qualityPart2 lefts2 tops'">
            <div class="modals2"></div>
            <img src="../assets/img/BusinessIntroduction/quality5.png" class="img14" />
            <div class="details2">
              <div>数据管理与统计计划  </div>
              <div class="tops2">数据核查、数据质量控制  </div>
              <div class="tops2">统计分析报告撰写    </div>
            </div>
          </div>
      </div>
      <div  @mouseover="hovers(10)" @mouseout="outs(10)">
          <div :class="hovers10?'qualityPart2 lefts2 tops':'qualityPart lefts2 tops'">
            <img src="../assets/img/BusinessIntroduction/quality6.png" class="qualityImg" />
            <div class="name">其他服务</div>
          </div>
          <div :class="hovers10?'qualityPart lefts2 tops':'qualityPart2 lefts2 tops'">
            <div class="modals2"></div>
            <img src="../assets/img/BusinessIntroduction/quality6.png" class="img14" />
            <div class="details2">
              <div>真实世界数据研究服务</div>
              <div class="tops2">I期病房建设</div>
              <div class="tops2">患者招募</div>
              <div class="tops2">研究中心启动</div>
              <div class="tops2">临床研发战略咨询</div>
            </div>
          </div>
      </div>
      
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        hovers1:false,
        hovers2:false,
        hovers3:false,
        hovers4:false,
        hovers5:false,
        hovers6:false,
        hovers7:false,
        hovers8:false,
        hovers9:false,
        hovers10:false,
      }
    },
    created() {
      
    },
    methods: {
      hovers(num){
        this['hovers'+num]=true;
      },
      outs(num){
        this['hovers'+num]=false;
      }
    }
  }
  

</script>

<style scoped>
  @media screen and (max-width: 960px){
    .yjbg{
      background: url('../assets/img/img28.png') no-repeat !important;
      background-size: 100% 100% !important;
      width: 7.5rem !important;
      height: 9.7rem;
      padding-left: 0 !important;
    }
    .yjbg>h4{
      color: #FFFFFF !important;
      margin-left: 0.35rem;
    }
    .yjbg>h4>span{
      background: #FFFFFF !important;
    }
    .yjbg>ul{
      display: block;
      /* padding-top: 0.42rem; */
    }
    .yjbg>ul>li{

    }
    .yjbg>ul>li>img{
      display: block;
      width: 1.22rem;
      height: 1.22rem;
      margin: 0 auto;
    }
    .yjbg>ul>li>p{
      display: block;
      width: 100%;
      font-size: 0.24rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 0.45rem;
      text-align: center;
      padding-top: 0.18rem;
      padding-bottom: 0.24rem;
    }
    .con{
      width: 100%;
      background: #F5F5F5;
    }
    .con>img{
      display: block;
      width:7.5rem;
      height: 3rem;
    }
    .yj2{
      border-bottom:0.32rem solid #f5f5f5;
      border-top:0.32rem solid #f5f5f5;
      display: block;
      width: calc( 100% - 0.35rem );
      background: #ffffff;
      padding-left:0.35rem;
      padding-bottom:0.05rem;
    }
    .yj2>h4{
      font-size: 0.26rem;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      line-height: 0.39rem;
      padding-top:0.35rem;
      padding-bottom:0.32rem;
    }
    .yj2>h4>span{
      display: inline-block;
      width: 0.03rem;
      height: 0.23rem;
      background: #0085D0;
      margin-right:0.07rem;
    }
    .bus{
      width:6.8rem;
    }
    .bus>img{
      display: block;
      width:6.8rem;
      height:3.5rem;
    }
    .bus>h3{
        font-size: 0.3rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        margin-top:0.32rem;
        margin-bottom:0.2rem;
      }
    .bus>p{
      font-size: 0.24rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 0.45rem;
      padding-bottom:0.2rem;
    }
    .yj{
      display: block;
      width: calc( 100% - 0.35rem );
      background: #ffffff;
      padding-left:0.35rem;
      border-bottom:0.32rem solid #f5f5f5;
    }
    .yj>h4{
      font-size: 0.26rem;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      line-height: 0.39rem;
      padding-top:0.35rem;
      padding-bottom:0.32rem;
    }
    .yj>h4>span{
      display: inline-block;
      width: 0.03rem;
      height: 0.23rem;
      background: #0085D0;
      margin-right:0.07rem;
    }
    .support{
      display: block;
      width:6.8rem;
    }
    .support>img{
      display: block;
      width: 100%;
      height:2rem;
    }
    .supportcon{
      display: block;
      width: 100%;
    }
    .supportcon>.con_left{
      display: block;
      width:50%;
      float:left;
    }
    .supportcon>.con_left>h5{
      font-size: 0.26rem;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      line-height: 0.3rem;
      padding-top:0.24rem;
    }
    .supportcon>.con_left>ul{
      padding-top:0.24rem;
      padding-bottom:0.15rem;
    }
    .supportcon>.con_left>ul>li{
      font-size: 0.24rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 0.3rem;
      display: flex;
      align-items: center;
      margin-bottom:0.15rem;
    }
    .supportcon>.con_left>ul>li>span{
      display: inline-block;
      width: 0.04rem;
      height: 0.04rem;
      background: #0085D0;
      border-radius: 50%;
      margin-right:0.12rem;
    }
  }

  @media screen and (min-width: 960px){
    .bussinessCntainer{
      width:100%;
      padding-top:90px;
    }
    .topBg{
      width:100%;
      height: 500px;
    overflow:hidden;
    }
    .goodness{
      font-size: 30px;
      font-family: Source Han Sans CN;
      font-weight: 600;
      color: #02B2B5;
      text-align:center;
      margin-top:127px;
      letter-spacing:2px;
    }
    .line{
      width: 42px;
  height: 6px;
  margin:0 auto;
  background: #02B2B5;
  margin-top:54px;
  text-align:center;
  margin-bottom:60px;
    }
    .goodnessCon{
      width:1280px;
      height:420px;
      margin:0 auto;
      display:flex;
      align-items:center;
    }
    .one{
      position:relative;
      width:290px;
      height:420px;
      display:block;
    }
    .one2{
      display:none;
    }
    .details{
      width: 290px;
  height: 420px;
  line-height:2;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  position:absolute;
  top:0px;
  left:0px;
  box-sizing:border-box;
  padding-right:24px;
  padding-top:52px;
  padding-left:24px;
    }
    .details2{
      width: 365px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  line-height:1.4;
  position:absolute;
  top:190px;
  left:25px;
    }
    .desc{
      width:290px;
      height:154px;
      background: #FAFAFA;
      padding-top:50px;
      margin-top:-12px;
    }
    .img1{
      width: 290px;
  height: 266px;
  overflow:hidden;
    }
    .img13{
      width: 290px;
  height: 470px;
  overflow:hidden;
    }
    .qualityText{
      font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
  margin-bottom:19px;
  text-align:center;
    }
    .qualityDesc{
      font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  text-align:center;
    }
    .lefts{
      margin-left:40px;
    }
    .serviceImg{
      width:100%;
      height: 800px;
      overflow:hidden;
    }
    .qualityCon{
      width:1280px;
      margin:0 auto;
      display:flex;
      align-items:center;
      flex-wrap:wrap;
      margin-bottom:139px;
    }
    .qualityPart{
      width: 390px;
  height: 576px;
  position:relative;
    }
    .img14{
      width: 390px;
  height: 556px;
    }
    .qualityPart2{
  display:none;
    }
    .qualityImg{
      width: 390px;
  height: 556px;
  overflow:hidden;
    }
    .name{
      font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  position:absolute;
  left:26px;
  bottom:40px;
  letter-spacing:2px;
    }
    .lefts2{
      margin-left:55px;
    }
    .tops{
      margin-top:40px;
    }
    .modals{
      width: 290px;
  height: 420px;
  position:absolute;
  left:0;
  top:0;
  background-color:rgba(0,0,0,0.2);
  z-index:9999;
    }
    .alreadyModuel{
      position: relative;
      width: 100%;
      height: 800px;
      background-color:rgba(0,0,0,0.35);
    }
    .desc1{
      width: 322px;
      text-align: center;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 600;
      color: #FFFFFF;
      position: absolute;
      left:16.371%;
      bottom:275px;
    }
    .desc2{
      width: 323px;
      text-align: center;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 600;
      color: #FFFFFF;
      position: absolute;
      left:33.055%;
      bottom:468px;
    }
    .desc3{
      width: 320px;
      text-align: center;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 600;
      color: #FFFFFF;
      position: absolute;
      left:50%;
      bottom:275px;
    }
    .desc4{
      width: 318px;
      text-align: center;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 600;
      color: #FFFFFF;
      position: absolute;
      left:66.527%;
      bottom:468px;
    }
    .tops2{
      margin-top:10px;
    }
    .modals2{
      width: 390px;
  height: 556px;
  position:absolute;
  top:0px;
  left:0px;
  background-color:rgba(0,0,0,0.35);
    }
    .modals3{
      width:100%;
      height: 400px;
      
  position:absolute;
  top:0px;
  left:0px;
  background-color:rgba(0,0,0,0.65);
    }
    .leftPic{
      width: 18px;
  height: 16px;
  position:absolute;
  left:15px;
  top:50px;
  overflow:hidden;
    }
    .rightPic1{
      width: 18px;
  height: 16px;
  position:absolute;
  right:18px;
  top:130px;
  overflow:hidden;
    }
    .rightPic2{
      width: 18px;
  height: 16px;
  position:absolute;
  right:27px;
  bottom:-10px;
  overflow:hidden;
    }
    .rightPic3{
      width: 18px;
  height: 16px;
  position:absolute;
  right:22px;
  bottom:-22px;
  overflow:hidden;
    }
    .rightPic4{
      width: 18px;
  height: 16px;
  position:absolute;
  right:15px;
  top:160px;
  overflow:hidden;
    }
  }
</style>
